import { Component, inject } from '@angular/core'
import packageInfo from '../../../../../package.json'
import { AsyncPipe, DatePipe } from '@angular/common'
import { LayoutService } from '../../services/layout.service'

@Component({
  standalone: true,
  imports: [DatePipe, AsyncPipe],
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  private layoutService = inject(LayoutService)
  public today: number = Date.now()
  public version = packageInfo.version

  apiVersion = this.layoutService.apiVersion
}
